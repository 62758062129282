// src/components/JobCard.jsx
import React, { useEffect, useRef, useState } from "react";
import { Card, Modal } from "antd";
import { Icon } from "@iconify/react";
import "./JobCard.scss";
import "./EmptyState.scss";

import { CalenderIcon, CarPaintingIcon, SuccessIcon } from "../../assets/svgs";
import { Button } from "../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { CardBody, CardHeader } from "react-bootstrap";
import { chartOptions, dashboardChartCardOptions } from "../../dummyData";
import Chart from "react-apexcharts";
import { getAllDocuments } from "../../firebase/firebaseHelpers";

export const JobCard = ({ job, onCancel, onComplete, onPay, onActivate, link }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isCompleted = job.status === 'completed' || job.status === 'paid';

  const handleCancel = () => {
    if (window.confirm('Do you want to cancel this job?')) {
      onCancel(job.id);
    }
  };

  const handleComplete = () => {
    onComplete();
  };

  const handleActivate = () => {
    onActivate(job.id);
  };

  const handlePayment = (paymentMethod) => {
    onPay(job.id, paymentMethod);
    setIsModalVisible(false);
  };

  const renderActions = () => {
    if (job.status === 'new') {
      return (
        <div className="job-actions">
          <Button isSecondary className="py-1 w-100 mr-3" onClick={handleCancel}>
            Cancel Job
          </Button>
          <Button className="py-1 w-100" onClick={handleActivate}>
            Activate Job
          </Button>
        </div>
      );
    } else if (job.status === 'active') {
      return (
        <div className="job-actions">
          <Button isSecondary className="py-1 w-100 mr-3" onClick={handleCancel}>
            Cancel Job
          </Button>
          <Button className="py-1 w-100" onClick={handleComplete}>
            Complete Job
          </Button>
        </div>
      );
    } else if (job.status === 'completed') {
      return (
        <div>
          <div className="job-actions">
            <Button className="w-100 py-2 px-1 mr-3 text-sm" onClick={() => handlePayment('cash')}>
              Pay with Cash
            </Button>
            <Button className="w-100 py-2 px-1 text-sm " onClick={() => handlePayment('card')}>
              Pay by Card
            </Button>
          </div>
          <div className="job-actions">

          <Button className="w-100 py-2 px-1 text-sm mt-1" onClick={() => handlePayment('bankTransfer')}>
            Pay with Bank Transfer
          </Button>
          <Button className="w-100 py-2 px-1 text-sm mt-1" onClick={() => handlePayment('other')}>
            Pay with Other
          </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Card className={`job-card ${isCompleted ? "completed" : ""}`}>
        {isCompleted && (
          <div className="text-end">
            <div className="completed-badge">{job.status === 'paid' ? 'Paid' : 'Completed'}</div>
          </div>
        )}
        {job.status === 'new' && (
          <div className="text-end">
            <div className="new-badge">New</div>
          </div>
        )}
        <h3 className="text-center">{job.vehicleName}</h3>
        <Link to={link} className="job-info text-white">
          <div className="d-flex justify-content-between align-item-center">
            <div>
              <span className="text-sm font-normal text-secondary">
                Service Type:
              </span>
              <p className="mt-1">{job?.serviceType?.join(' , ')}</p>
            </div>
            <CarPaintingIcon />
          </div>
          <div className="d-flex justify-content-between align-item-center">
            <div>
              <span className="text-sm font-normal text-secondary">
                Submission Date:
              </span>
              <p className="mt-1">{job.submissionDate}</p>
            </div>
            <CalenderIcon />
          </div>
          <p className="estimated-completion">
            <span className="text-xs font-normal text-secondary">
              Estimated Completion: {job.completionTimeline} hours
            </span>
          </p>
        </Link>
        {renderActions()}
      </Card>

      <Modal
        title="Job Completed"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <p>The job has been completed. Please select a payment method:</p>
        <div className="job-actions">
          <Button className="w-100 mr-3" onClick={() => handlePayment('cash')}>
            Pay with Cash
          </Button>
          <Button className="w-100" onClick={() => handlePayment('bankTransfer')}>
            Pay with Bank Transfer
          </Button>
          <Button className="w-100" onClick={() => handlePayment('card')}>
            Pay by Card
          </Button>
          <Button className="w-100" onClick={() => handlePayment('other')}>
            Pay with Other
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default JobCard;
export const EmptyState = ({
  icon,
  message,
  actionText,
  onAction,
  customIcon,
}) => (
  <div className="empty-state">
    {customIcon ? (
      <div className="empty-icon">{customIcon}</div>
    ) : (
      <Icon icon={icon} className="empty-icon" />
    )}
    <p>{message}</p>
    {actionText && (
      <Button className="w-100 mt-5" onClick={onAction}>
        {actionText}
      </Button>
    )}
  </div>
);

export const SuccessModal = ({ isVisible, onClose, message }) => {
  return (
    <Modal
      visible={isVisible}
      footer={null}
      closable={false}
      centered
      className="success-modal"
    >
      <div className="success-modal-content text-white text-center">
        <h2 className="mb-4">Success</h2>
        <div className="mb-3">
          {/* <CheckOutlined /> */}
          <SuccessIcon />
        </div>
        <p>{message}</p>
        <Button onClick={onClose} className="w-75 mt-3">
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export const DashboardDisplayCard = ({
  title,
  subtitle,
  headlineFocus,
  headlineText,
}) => {
  return (
    <Card className="dashboard-display-card">
      <CardBody>
        <h6 className="text-center title mb-2">{title}</h6>
        <h6 className="text-center subtitle">{subtitle}</h6>
        <p className="m-0 text-center">
          <span className="color-primary">{headlineFocus}</span> {headlineText}
        </p>
      </CardBody>
    </Card>
  );
};
// const dashboardChartCardOptions = [
//   { title: 'Weekly', flowAt: 0 },
//   { title: 'Monthly', flowAt: 1 },
//   { title: 'Yearly', flowAt: 2 },
// ];
export const DashboardChartCard = ({ title }) => {
  const navigate = useNavigate();
  const [flowAt, setFlowAt] = useState(0);
  const categoriesRef = useRef([]);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        background: "transparent",
        toolbar: { show: false },
        events: {}
      },
      yaxis: {
        labels: {
          style: { colors: "#FFFFFF" },
        },
        opposite: true,
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      grid: { show: false },
      xaxis: {
        categories: [],
        labels: {
          style: { colors: "#FFFFFF" },
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      colors: ["#B41515"],
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: 20,
        },
      },
    },
    series: [{
      name: 'Orders',
      data: []
    }]
  });

  useEffect(() => {
    fetchChartData();
  }, [flowAt]);

  const fetchChartData = async () => {
    try {
      const jobs = await getAllDocuments('jobs');
      const { categories, seriesData } = processData(jobs);
      categoriesRef.current = categories;
      setChartData(prevState => ({
        ...prevState,
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: categories
          }
        },
        series: [{
          name: 'Orders',
          data: seriesData
        }]
      }));
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const processData = (jobs) => {
    let categories = [];
    let seriesData = [];

    if (flowAt === 0) { // Weekly
      categories = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      seriesData = new Array(7).fill(0);
      jobs.forEach(job => {
        const date = new Date(job.submissionDate);
        seriesData[date.getDay()] += 1;
      });
    } else if (flowAt === 1) { // Monthly
      categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      seriesData = new Array(12).fill(0);
      jobs.forEach(job => {
        const date = new Date(job.submissionDate);
        seriesData[date.getMonth()] += 1;
      });
    } else { // Yearly
      const currentYear = new Date().getFullYear();
      categories = [currentYear - 2, currentYear - 1, currentYear];
      seriesData = new Array(3).fill(0);
      jobs.forEach(job => {
        const date = new Date(job.submissionDate);
        const yearIndex = categories.indexOf(date.getFullYear());
        if (yearIndex !== -1) {
          seriesData[yearIndex] += 1;
        }
      });
    }

    return { categories, seriesData };
  };

  const handleChartClick = (event, chartContext, config) => {
    if (config.dataPointIndex >= 0) {
      const selectedDate = categoriesRef.current[config.dataPointIndex];
      console.log("Selected Date:", selectedDate);
      navigate(`/selected-date-jobs/${selectedDate}/${flowAt}`);
    }
  };

  return (
    <Card className="dashboard-chart-card">
      <CardHeader className="px-3 m-0" style={{ paddingTop: "1rem" }}>
        <h6 className="title m-0">{title}</h6>
        <div className="d-flex align-items-center justify-content-between">
          {dashboardChartCardOptions.map((item, key) => (
            <Button
              key={key}
              onClick={() => setFlowAt(item.flowAt)}
              className={`bg-transparent font-bold border-none text-start px-0  ${
                flowAt === item.flowAt ? "color-primary" : "color-half-white"
              }`}
            >
              {item.title}
            </Button>
          ))}
        </div>
      </CardHeader>
      <div>
        <Chart
          height={300}
          width={"100%"}
          options={{
            ...chartData.options,
            chart: {
              ...chartData.options.chart,
              events: {
                click: handleChartClick
              }
            }
          }}
          series={chartData.series}
          type="bar"
        />
      </div>
    </Card>
  );
};

export const DepartmentsCard = ({ departments }) => {
  return (
    <Card className="dashboard-display-card   " style={{ height: "100%" }}>
      <h6 className="title">Departments</h6>
      {departments?.map((item, key) => (
        <Link
        to={`/alljobs?goto=${item?.title.toUpperCase()}`}
          key={key}
          className="d-flex mb-2 full-width justify-content-between align-items-center"
          
        >
          <h5 className="m-0 text-white">{item?.title}</h5>
          <img
            src={item?.icon}
            height={50}
            width={45}
            className="object-fit-contain"
          />
        </Link>
      ))}
    </Card>
  );
};

//this is admin side card
export const JobCardCompletedCard = ({ job, link }) => {
  return (
    <Card className={`job-card completed`}>
      <div className="text-end mb-3">
        <div className="completed-badge">
          {job.status === "paid" ? "Paid" : "Completed"}
        </div>
      </div>

      <h3 className="text-center">{job?.carModel}</h3>
      <Link to={link} className="job-info text-white">
        <div className="d-flex justify-content-between align-item-center">
          <div>
            <span className="text-sm font-normal text-secondary">
              Service Type:
            </span>
            <p className="mt-1">{job?.serviceType}</p>
          </div>
          <CarPaintingIcon />
        </div>
        <div className="d-flex justify-content-between align-item-center">
          <div>
            <span className="text-sm font-normal text-secondary">
              Submission Date:
            </span>
            <p className="mt-1">{job?.submissionDate}</p>
          </div>
          <CalenderIcon />
        </div>
        <p className="estimated-completion">
          <span className="text-xs font-normal text-secondary">
            Estimated Completion: {job?.completionTimeline} hours
          </span>
        </p>
      </Link>
    </Card>
  );
};
