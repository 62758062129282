import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import './JobDetails.scss';
import { BackButton, Button } from '../../components/common/Button';
import Input, { TimePicker } from '../../components/common/Input';
import { Form, Input as AntInput,  InputNumber, Skeleton, notification, Spin, DatePicker } from 'antd';
import { Select } from '../../components/common/Select';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SuccessModal } from '../../components/cards';
import { useAuth } from '../../hooks/useAuth';
import { getDocumentById, updateDocument } from '../../firebase/firebaseHelpers';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from 'moment';

const GenrateQuotaion = () => {
  const { jobId } = useParams();
  const location = useLocation();

  const isUpdating = new URLSearchParams(location.search).get('update') === 'true';

  const { user } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  

  const [services, setServices] = useState([{ service: 'N/A', quantity: null, partType: '', package: 0, total: 0 }]);

  
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobData = await getDocumentById('jobs', jobId);
        setJob(jobData);
        
        if (isUpdating) {
          // Prepopulate form fields for updating
          form.setFieldsValue({
            serviceType: jobData.serviceType,
            completionTimeline: jobData.completionTimeline,
            submissionTime: jobData.submissionTime ? moment(jobData.submissionTime, 'YYYY-MM-DD HH:mm') : null,
            discount: jobData.discount,
            comment: jobData.comment,
          });
          setServices(jobData.services);
        } else {
          // Set default values for new job
          form.setFieldsValue({
            completionTimeline: '24hours',
            submissionTime: null,
            discount: 0,
            comment: jobData.comment,
          });
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId, form, isUpdating]);

  const handleShareAndStartJob = async (values) => {
    
    setLoadingBtn(true);
    if (services.length === 0) {
      notification.error({
        message: 'Error',
        description: 'Please add at least one service before submitting.',
      });
      setLoadingBtn(false);
      return;
    }
    try {
      let formattedSubmissionTime = null;
      if (values.submissionTime) {
        formattedSubmissionTime = values.submissionTime.format('YYYY-MM-DD HH:mm');
      }
  
      const updatedJobData = {
        ...values,
        submissionTime: formattedSubmissionTime,
        services: services,
        status: 'new',
        grandTotal: calculateGrandTotal().toFixed(2),
        updatedAt: new Date().toISOString(),
        updatedBy: user.username || user.email,
      };
      await updateDocument('jobs', jobId, updatedJobData);
      setIsSuccessModalVisible(true);
      setLoadingBtn(false);
    } catch (error) {
      console.error("Error updating job:", error);
      notification.error({
        message: 'Error',
        description: 'There was an error updating the job. Please try again.',
      });
      setLoadingBtn(false);
    }
  };

  const handleServiceChange = (index, field, value) => {
    const newServices = [...services];
    
    if (field === 'service' || field === 'quantity' || field === 'package') {
      // Check if value is an event object (for HTML inputs) or direct value
      const actualValue = value?.target?.value !== undefined ? value.target.value : value;
      
      // Create an object if it doesn't exist
      if (typeof newServices[index] === 'string') {
        newServices[index] = { [field]: actualValue };
      } else {
        newServices[index][field] = actualValue;
      }
    } else {
      newServices[index][field] = value;
    }

    if (field === 'quantity' || field === 'package') {
      newServices[index].total = Number(newServices[index].quantity) * Number(newServices[index].package);
    }
    setServices(newServices);
};
  const addService = () => {
    setServices([...services, { service: '', quantity: 1, partType: '', package: 0, total: 0 }]);
  };

  const removeService = (index) => {
    const newServices = services.filter((_, i) => i !== index);
    setServices(newServices);
  };

  const calculateGrandTotal = () => {
    const subtotal = services.reduce((acc, service) => acc + service.total, 0);
    const discount = form.getFieldValue('discount') || 0;
    return subtotal * (1 - discount / 100);
  };
  
  

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh'  // This ensures the height takes the full screen
      }}>
        <Spin size="large" />
      </div>
    );
  }
 const serviceList= ["DENTING", "PAINTING", "MECHANICAL", "ELECTRICIAN", "INSPECTION"]
  return (
    <div className="job-details-container">
      <div className="header d-flex justify-content-between flex-wrap ">
        <BackButton />
    
      </div>
      <h3 className='mb-4'>Quotation Details</h3>
      
      <div >
      
        <div className="content">
          <div className="row">
            <div className="col-md-4">
              <h5 className='text-primary'>Client's Contact</h5>
              <div className="">
                <div className='text-md mb-1'>Name: <span className='text-sm font-normal'>{job.customerName}</span></div>
                <div className='text-md mb-1'>Email: <span className='text-sm font-normal'>{job.emailAddress}</span></div>
                <div className='text-md mb-1'>Cell: <span className='text-sm font-normal'>{job.phoneNumber}</span></div>
              </div>
            </div>
            
            <div className="col-md-8">
              <div className="row ">
                <div className="col-md-7">
                  <section className="vehicle-information">
                    <h2>Vehicle Information</h2>
                    <div className="">
                      <div className='text-md mb-1'>Vehicle Year: <span className='text-sm'>{job.vehicleYear}</span></div>
                      <div className='text-md mb-1'>Vehicle Make: <span className='text-sm'>{job.vehicleMake}</span></div>
                      <div className='text-md mb-1'>Submission Date: <span className='text-sm'>{job.submissionDate}</span></div>
                      <div className='text-md mb-1'>Vehicle Mileage: <span className='text-sm'>{job.vehicleMileage}</span></div>
                      <div className='text-md mb-1'>Service Required: <span className='text-sm'>{job?.services?.join(', ')}</span></div>
                    </div>
                  </section>
                </div>
                <div className="col-md-5 h-100">
                  <h5 className='text-primary'>Contractor's Contact</h5>
                  <div className="">
                    <div className='text-md mb-1'>Name: <span className='text-sm font-normal'>{user.username}</span></div>
                    <div className='text-md mb-1'>Email: <span className='text-sm font-normal'>{user.email}</span></div>
                    <div className='text-md mb-1'>Cell: <span className='text-sm font-normal'>{user.phone || 'N/A'}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="mb-4 text-primary">Service Required</h4>
        
        <Form form={form} layout="vertical"
        onFinish={handleShareAndStartJob}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Item name="serviceType" label={null}
              
              rules={[
                { required: true, message: 'Please input service Type' },
              ]}>
                <Select
                mode="multiple"
                label="Service Type"
                
                  placeholder="Select service type"
                  options={serviceList.map(service => ({ value: service, label: service }))}
                />
              </Form.Item>
              <Form.Item name="completionTimeline" label={null} 
               rules={[
                { required: true, message: 'Please input Completion Timeline' },
              ]}
              >
               <Input label={"Completion Timeline (hours)"} type="number"/>
              </Form.Item>
              <Form.Item 
  className="w-100" 
  name="submissionTime" 
  label={null}
  rules={[
    { required: true, message: 'Please input Submission Time' },
  ]}
>
  <div className="border rounded-lg">
    <label className="input-label pl-2">Submission Time</label>
    <DatePicker
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      placeholder="Select Date and Time"
      className="w-100 pt-0 mt-0"
      style={{border:'none'}}
      onChange={(dateTime) => {
        if (dateTime) {
          form.setFieldsValue({ submissionTime: dateTime }); // Changed from submissionDateTime to submissionTime
        } else {
          form.setFieldsValue({ submissionTime: null });
        }
      }}
    />
  </div>
</Form.Item>
              <Form.Item 
  className='w-100'
  name="discount" 
  label={null}
  rules={[
    { required: true, message: 'Please input discount percentage' },
    // { type: 'number', min: 0, max: 100, message: 'Discount must be between 0 and 100%' }
  ]}
>
  <Input
  label={"Discount %"}
  type="number"
    className="w-100"
    placeholder="Discount %"
    min={0}
    max={100}
    formatter={value => `${value}%`}
    parser={value => value.replace('%', '')}
  />
</Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item className="mb-2" name="comment" label={<span className='text-white'>Comment</span>}>
                <AntInput.TextArea defaultValue={job.comment} rows={11} placeholder="Type message here" />
              </Form.Item>
            </div>
          </div>
          <div className="table-container border-primary rounded-lg p-3">
            <div className="">
              <div className="row">
                <div className="col m-auto text-center">
                  <p className='font-bold text-white mb-2'>Services</p>
                </div>
                <div className="col m-auto text-center">
                  <p className='font-bold text-white mb-2'>Quantity</p>
                </div>
                <div className="col m-auto text-center">
                  <p className='font-bold text-white mb-2'>Part type</p>
                </div>
                <div className="col d-flex justify-content-between align-items-center">
                  <p className='font-bold text-white mb-2'>Package</p>
                  {/* <Icon fontSize={18} className='text-white' icon="ep:arrow-up-bold" /> */}
                </div>
                <div className="col m-auto text-center">
                  <p className='font-bold text-white mb-2'>Total</p>
                </div>
                <div className="col m-auto text-center">
                  <p className='font-bold text-white mb-2'>Action</p>
                </div>
              </div>
            </div>
            {services.map((service, index) => (
              <div className="" key={index}>
                <div className="row">
                  <div className="col m-auto text-center">
                    <Input
                    className={"py-1"}
    placeholder="Tire"
    value={service.service}
    onChange={(e) => handleServiceChange(index, 'service', e)}
  />
                  
                  </div>
                  <div className="col m-auto text-center">
                    <Input
                    className={"py-1"}

                    type="number"
                      value={service.quantity}
                      onChange={(value) => handleServiceChange(index, 'quantity', value)}
                      min={1}
                    />
                  </div>
                  <div className="col m-auto text-center">
                    <Select
                      value={service.partType}
                      onChange={(value) => handleServiceChange(index, 'partType', value)}
                      options={[
                        { value: 'OEM', label: 'OEM' },
                        { value: 'Aftermarket', label: 'Aftermarket' },
                        { value: 'Used', label: 'Used' },
                        // { value: 'None', label: 'None' }

                      ]}
                    />
                  </div>
                  <div className="col d-flex justify-content-between align-items-center">
                    <Input
                    className={"py-1"}

                      type="number"
                      value={service.package}
                      onChange={(value) => handleServiceChange(index, 'package', value)}
                      min={0}
                    />
                  </div>
                  <div className="col m-auto text-center">
                    <Input   
                    className={"py-1"}
                    
                    value={service.total}  />
                  </div>
                  <div className="col m-auto text-center">
                    <Icon fontSize={22} className='text-danger' onClick={() => removeService(index)} icon={'material-symbols-light:delete-outline'}/>
                    
                  </div>
                </div>
              </div>
            ))}
            <Link onClick={(e)=>{
              e.preventDefault()
              addService()}} className="mt-3 text-white ">
            <Icon icon={"gridicons:add-outline"}  className='mr-2' fontSize={20} />
            Add Service</Link>
            <div className="row mt-3">
              <div className="col">
                <p className='text-white text-lg  font-bold p-4'>Grand Total</p>
                <Input className="py-2 text-center" size="large" value={calculateGrandTotal().toFixed(2 )}  />
              </div>
            </div>
          </div>

          <p className='mt-3 text-white text-base'>
            <span className='font-bold'>Prepared By:</span> {user.username}
          </p>
          
          <p className='mt-3 text-white text-base'>
            <span className='font-bold'>Prepared For:</span> {job.customerName}
          </p>
      <div className="col-md-5 m-auto py-5">
        <Button  
        htmlType='submit'
        loading={loadingBtn}
        className="w-100">
                      {isUpdating ? 'Update Job' : 'Start Job'}


        </Button>
      </div>
        </Form>
      </div>
      
    
      <SuccessModal
        isVisible={isSuccessModalVisible}
        onClose={() => {
          setIsSuccessModalVisible(false);
          navigate('/dashboard');
        }}
        message="Job has started and your quotation has been sent to client's email successfully"
      />
    </div>
  );
};

export default GenrateQuotaion;