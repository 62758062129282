import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Select, Checkbox, Upload, message, notification, Image, Modal } from "antd";
import { UploadOutlined, AudioOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import "./CreateJob.scss";
import { Button } from "../../components/common/Button";
import { addDocument, uploadImage } from "../../firebase/firebaseHelpers";
import { Link, useNavigate } from "react-router-dom";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useAuth } from "../../hooks/useAuth";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const { TextArea } = Input;

const CreateJob = () => {
  const  {user }=useAuth()
  const [form] = Form.useForm();
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const recorderControls = useAudioRecorder();
  const [videoFile, setVideoFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
  const [isVideoPreviewVisible, setIsVideoPreviewVisible] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const onFinish = async (values) => {
    console.log(values,"values")
    setLoading(true);
    try {
      // Upload images
      const imageUrls = await Promise.all(
        imageList.map((file) => uploadImage(file.originFileObj, 'images'))
      );

      // Upload video
      let videoUrl = null;
      if (videoFile) {
        videoUrl = await uploadImage(videoFile, 'videos');
      }

      // Upload audio
      let audioUrl = null;
      if (audioBlob) {
        audioUrl = await uploadImage(audioBlob, 'audio');
      }

      // Prepare data for Firebase
      const jobData = {
        ...values,
        services: selectedServices,
        userId:user.id,
        images: imageUrls,
        video: videoUrl,
        audio: audioUrl,
        status: 'new',
      };
      // dashboard/job-details/${job.id}
      // Add document to Firebase
const jobId=await addDocument('jobs', jobData);

      notification.success({
        message: 'Job Created',
        description: 'Your job has been successfully created.',
      });
      navigate(`/dashboard/job-details/${jobId}`);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an error creating the job. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  

  const handleServiceChange = (service) => {
    setSelectedServices((prev) =>
      prev.includes(service)
        ? prev.filter((s) => s !== service)
        : [...prev, service]
    );
  };

  const handleImageUpload = ({ fileList }) => {
    // debugger
    const newFileList = fileList.slice(0, 6);
    setImageList(newFileList);
    form.setFieldsValue({ images: newFileList });
  };

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setAudioBlob(blob);
  };
  
  
  const handleVideoPreview = () => {
    if (videoFile) {
      const videoUrl = URL.createObjectURL(videoFile);
      setVideoPreviewUrl(videoUrl);
      setIsVideoPreviewVisible(true);
    }
  };
  

  
  
  
  return (
    <div className="create-job-container">
      <div className="create-job-header">
        <h2 className="text-xl font-bold text-secondary">
          <Button
            onClick={() => navigate(-1)}
            className="p-0"
            iconSize={40}
            icon={"iconamoon:arrow-left-2-bold"}
          />
          <span className="ml-4">Create Job</span>
        </h2>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="create-job-form"
        requiredMark={false}
      >
        {/* Vehicle Information section */}
        <div className="section vehicle-info">
          <h3 className="section-title text-white align-items-center  text-lg">
            <span className="section-number mr-3 py-2">1</span>
            Vehicle Information
          </h3>
          <div className="row">
            <div className="col-md-4">
              <Form.Item className="mb-2" name="vehicleYear" label="Vehicle Year" rules={[{ required: true, message: 'Please enter vehicle year' }]}>
                <Input  type="number" placeholder="Enter vehicle year" />
              </Form.Item>
              <Form.Item className="mb-2" name="vehicleMake" label="Vehicle Make" rules={[{ required: true, message: 'Please select vehicle make' }]}>
              <Select
    showSearch
    placeholder="Select vehicle make"
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.children.toLowerCase().includes(input.toLowerCase())
    }
    filterSort={(optionA, optionB) =>
      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
    }
  >
  <Select.Option value="ford">Ford</Select.Option>
<Select.Option value="chevrolet">Chevrolet</Select.Option>
<Select.Option value="cadillac">Cadillac</Select.Option>
<Select.Option value="gmc">GMC</Select.Option>
<Select.Option value="buick">Buick</Select.Option>
<Select.Option value="dodge">Dodge</Select.Option>
<Select.Option value="jeep">Jeep</Select.Option>
<Select.Option value="chrysler">Chrysler</Select.Option>
<Select.Option value="tesla">Tesla</Select.Option>
<Select.Option value="lincoln">Lincoln</Select.Option>
<Select.Option value="ram">RAM</Select.Option>
<Select.Option value="mercedes-benz">Mercedes-Benz</Select.Option>
<Select.Option value="bmw">BMW</Select.Option>
<Select.Option value="audi">Audi</Select.Option>
<Select.Option value="volkswagen">Volkswagen</Select.Option>
<Select.Option value="porsche">Porsche</Select.Option>
<Select.Option value="opel">Opel</Select.Option>
<Select.Option value="maybach">Maybach</Select.Option>
<Select.Option value="toyota">Toyota</Select.Option>
<Select.Option value="honda">Honda</Select.Option>
<Select.Option value="nissan">Nissan</Select.Option>
<Select.Option value="mazda">Mazda</Select.Option>
<Select.Option value="subaru">Subaru</Select.Option>
<Select.Option value="mitsubishi">Mitsubishi</Select.Option>
<Select.Option value="suzuki">Suzuki</Select.Option>
<Select.Option value="lexus">Lexus</Select.Option>
<Select.Option value="infiniti">Infiniti</Select.Option>
<Select.Option value="acura">Acura</Select.Option>
<Select.Option value="isuzu">Isuzu</Select.Option>
<Select.Option value="ferrari">Ferrari</Select.Option>
<Select.Option value="lamborghini">Lamborghini</Select.Option>
<Select.Option value="maserati">Maserati</Select.Option>
<Select.Option value="alfa-romeo">Alfa Romeo</Select.Option>
<Select.Option value="fiat">Fiat</Select.Option>
<Select.Option value="pagani">Pagani</Select.Option>
<Select.Option value="lancia">Lancia</Select.Option>
<Select.Option value="aston-martin">Aston Martin</Select.Option>
<Select.Option value="bentley">Bentley</Select.Option>
<Select.Option value="rolls-royce">Rolls-Royce</Select.Option>
<Select.Option value="jaguar">Jaguar</Select.Option>
<Select.Option value="land-rover">Land Rover</Select.Option>
<Select.Option value="mclaren">McLaren</Select.Option>
<Select.Option value="mini">Mini</Select.Option>
<Select.Option value="lotus">Lotus</Select.Option>
<Select.Option value="peugeot">Peugeot</Select.Option>
<Select.Option value="citroen">Citroën</Select.Option>
<Select.Option value="renault">Renault</Select.Option>
<Select.Option value="bugatti">Bugatti</Select.Option>
<Select.Option value="hyundai">Hyundai</Select.Option>
<Select.Option value="kia">Kia</Select.Option>
<Select.Option value="genesis">Genesis</Select.Option>
<Select.Option value="volvo">Volvo</Select.Option>
<Select.Option value="koenigsegg">Koenigsegg</Select.Option>
<Select.Option value="geely">Geely</Select.Option>
<Select.Option value="byd">BYD</Select.Option>
<Select.Option value="great-wall">Great Wall Motors</Select.Option>
<Select.Option value="changan">Changan</Select.Option>
<Select.Option value="haval">Haval</Select.Option>
<Select.Option value="li-auto">Li Auto</Select.Option>
<Select.Option value="baic">BAIC</Select.Option>
<Select.Option value="lucid">Lucid Motors</Select.Option>
<Select.Option value="rivian">Rivian</Select.Option>
<Select.Option value="other">Other</Select.Option>

    
  </Select>
              </Form.Item>
              <Form.Item className="mb-2" name="vehicleName" label="Vehicle Name" rules={[{ required: true, message: 'Please enter vehicle name' }]}>
                <Input placeholder="Enter vehicle name" />
              </Form.Item>
              <Form.Item className="mb-2" name="vinNumber" label="vin Number" rules={[{ required: true, message: 'Please enter vin Number' }]}>
                <Input placeholder="Enter vin Number" />
              </Form.Item>
              
              <Form.Item className="mb-2" name="vehicleNumber" label="Vehicle Number" rules={[{ required: true, message: 'Please enter vehicle number' }]}>
                <Input placeholder="Enter vehicle number" />
              </Form.Item>
              <Form.Item className="mb-2" name="vehicleMileage" label="Vehicle Mileage" rules={[{ required: true, message: 'Please enter vehicle mileage' }]}>
                <Input placeholder="Enter vehicle mileage" />
              </Form.Item>
            </div>
            <div className="col-md-8">
              <div className="row">
              <div className="col-md-8">
            <div className="media-section h-100">
              <Form.Item
                className="mb-2"
                name="images"
                label={
                  <span>
                    Images
                    <span className="bg-secondary ml-3 px-1 py-1 text-sm">
                      <Icon
                        fontSize={18}
                        className="text-danger"
                        icon={"mdi:info-outline"}
                      />
                      <span className="ml-2 text-sm ">
                        Must upload images & video
                      </span>
                    </span>
                  </span>
                }
           
                rules={[
                  {
                    required: true,
                    message: 'Please upload at least one image',
                    validator: (_, value) => {
                      if (imageList.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please upload at least one image');
                    },
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  fileList={imageList}
                  accept="image/jpeg,image/png,image/jpg"
                  multiple
               
                  onPreview={handlePreview}

                  onChange={handleImageUpload}
                  beforeUpload={(file) => {
                    console.log('beforeUpload called:', file);
                    // This prevents automatic upload
                    return false;
                  }}                >
                  {imageList.length >= 6 ? null : (
                    <div className="text-white">
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
                  {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
            </div>
          </div>
          <div className="col-md-4 text-white">
          <Form.Item
        className="mb-2 text-white"
        name="video"
        label="Video"
        rules={[
          {
            required: true,
            message: 'Please upload a video',
            validator: (_, value) => {
              if (videoFile) {
                return Promise.resolve();
              }
              return Promise.reject('Please upload a video');
            },
          },
        ]}
      >
        <Upload
          accept="video/mp4,video/avi"
          listType="picture"
          className="text-white"
          beforeUpload={(file) => {
            console.log('beforeUpload called:', file);
            return false;
          }}
          onChange={(info) => {
            console.log('onChange called:', info);
            const { file } = info;
            if (file.status !== 'removed') {
              const videoFile = file.originFileObj || file;
              setVideoFile(videoFile);
              form.setFieldsValue({ video: [videoFile] });
            } else {
              setVideoFile(null);
              form.setFieldsValue({ video: [] });
            }
          }}
          maxCount={1}
          fileList={videoFile ? [{ ...videoFile, status: 'done' }] : []}
        >
          <Button  className={"text-white"} htmlType="default">
            <UploadOutlined className={"text-white"} /> Upload Video
          </Button>
        </Upload>
      </Form.Item>
      {videoFile && (
        <Link onClick={handleVideoPreview} className="mt-2 mb-2">
          Preview Video
          <div className="mb-2"></div>
        </Link>
      )}

          </div>
              </div>
              <div className="bg-secondary rounded-lg py-2 px-2">
              <div className="d-flex justify-content-between align-items-center">
        {audioBlob ? (
          <>
            <audio src={URL.createObjectURL(audioBlob)} controls className="w-75" />
            <Icon
              icon="mdi:delete-outline"
              className="text-danger cursor-pointer"
              fontSize={24}
              onClick={() => setAudioBlob(null)}
            />
          </>
        ) : (
          <>
            <p className="mb-0 font-bold text-white">
              {recorderControls.isRecording ? "Recording..." : "Add Voice Note"}
            </p>
            <AudioRecorder 
              onRecordingComplete={addAudioElement}
              // recorderControls={recorderControls}
              downloadOnSavePress={false}
              downloadFileExtension="mp3"
              showVisualizer={true}
              
            />
          </>
        )}
      </div>
                {/* <div className="d-flex justify-content-between">
                  <p className="mb-0 text-muted font-bold">Add Voice Note</p>
                  <div
                    className={`bg-danger rounded-full p-1 ${isRecording ? 'animate-pulse' : ''}`}
                    onClick={startRecording}
                    style={{ cursor: 'pointer' }}
                  >
                    <Icon icon={'ph:microphone'} className="text-white" fontSize={24}/>
                  </div>
                </div> */}


              </div>
            </div>
          </div>
        </div>

        {/* Services Needed section */}
        <div className="section services-needed">
          <h3 className="section-title text-white align-items-center  text-lg">
            <span className="section-number mr-3 py-2">2</span>
            Services Needed
          </h3>
          <Form.Item
            name="services"
            rules={[{ required: true, message: 'Please select at least one service' }]}
          >
            <div className="services-checkboxes">
              {["DENTING", "PAINTING", "MECHANICAL", "ELECTRICIAN", "INSPECTION"].map((service) => (
                <Checkbox
                  key={service}
                  onChange={() => handleServiceChange(service)}
                  checked={selectedServices.includes(service)}
                >
                  {service}
                </Checkbox>
              ))}
            </div>
          </Form.Item>
        </div>

        {/* Contact Details section */}
        <div className="section contact-details">
          <h3 className="section-title text-white align-items-center  text-lg">
            <span className="section-number mr-3 py-2">3</span>
            Contact Details
          </h3>
          <div className="row">
            <div className="col-md-4">
              <Form.Item className="mb-2" name="customerName" label="Customer Name" rules={[{ required: true, message: 'Please enter customer name' }]}>
                <Input placeholder="Enter customer name" />
              </Form.Item>
              <Form.Item className="mb-2" name="phoneNumber" label="Enter Your Phone Number" rules={[{ required: true, message: 'Please enter phone number' }]}>
                {/* <Input type="number" placeholder="Enter phone number" /> */}
                <PhoneInput
                style={{
                  height:33
                }}
        international
        countryCallingCodeEditable={true}
        defaultCountry="AE"
        className="custom-phone-input border rounded-md pl-3" // Add this class

        placeholder="Enter phone number"
      />
              </Form.Item>
        
        
          
              <Form.Item className="mb-2" name="submissionDate" label="Submission Date" rules={[{ required: true, message: 'Please enter submission date' }]}>
                <Input type="date" placeholder="DD/MM/YYYY" />
              </Form.Item>
            </div>
            <div className="col-md-8">
              <Form.Item className="mb-2" name="comment" label="Comment" rules={[{ required: true, message: 'Please enter a comment' }]}>
                <TextArea rows={14} placeholder="Type message here" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="col-md-4 m-auto">
          <Form.Item className="mb-2">
            <Button htmlType="submit" className="w-100" loading={loading}>
              Create Job
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        title=""
        visible={isVideoPreviewVisible}
        onCancel={() => setIsVideoPreviewVisible(false)}
        footer={null}
        width={800}
      >
        <video width="100%" controls>
          <source src={videoPreviewUrl} type={videoFile?.type} />
          Your browser does not support the video tag.
        </video>
      </Modal>
    </div>
  );
};

export default CreateJob;